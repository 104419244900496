import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import { showHrAndGhr } from '~/const'
import encryptor from '~/plugins/encryption.js'

export const state = () => ({
  data: [],
  loading: false,
  error: '',
  activeGame: '',
  competionData: [],
  eventCompetionData: [],
  premiumSportsbookData: []
})

export const mutations = {
  set_data(state, data) {
    data.map((d) => {
      switch (d.name.toLowerCase()) {
        case 'cricket':
          d.sort = 0
          break
        case 'soccer':
          d.sort = 1
          break
        case 'tennis':
          d.sort = 2
          break
        case 'Live Casino':
          d.sort = 3
          break
        case 'Live Card':
          d.sort = 4
          break
        case 'horse racing':
          d.sort = 5
          break
        case 'greyhound racing':
          d.sort = 6
          break
        default:
          if (d.allMarketCount > 0) {
            d.sort = 7
            break
          }
          d.sort = 8
          break
      }
      return d
    })
    data.sort((a, b) => a.sort - b.sort)
    state.data = data

    // state.data = remove(state.data, function (singleGame) {
    //   return !singleGame.disabledEvent
    // })

    const newGame = find(state.data, function (singleGame) {
      return singleGame.allMarketCount > 0
    })
    if (!isEmpty(newGame)) {
      state.activeGame = newGame.name
    } else {
      state.activeGame = 'Cricket'
    }
  },
  set_data_premium_sportsbook(state, data) {
    state.premiumSportsbookData = data
  },
  set_competition_data(state, data) {
    state.competionData = data
  },
  set_event_competition_data(state, data) {
    state.eventCompetionData = data
  },

  empty_data(state, data) {
    state.competionData = []
    state.eventCompetionData = []
    // state.data = []
  },

  // set_data_event_access(state, data) {
  //   state.eventData = data
  // },
  set_error(state, data) {
    state.error = data
  },
  set_loading(state, data) {
    state.loading = data
  },
  reset_data(state) {
    state.data = []
  }
}

export const actions = {
  async get({ commit, state }) {
    // This will stored data for 10mins.
    if (
      localStorage.getItem('set_CategoriesForNav_timezone') &&
      localStorage.getItem('set_CategoriesForNav')
    ) {
      const aa = localStorage.getItem('set_CategoriesForNav_timezone')
      const now = new Date().getTime()
      const distance = now - aa
      const seconds = Math.floor(distance / 1000)
      const minutes = Math.floor(seconds / 60)
      if (minutes >= 10) {
        localStorage.removeItem('set_CategoriesForNav')
        localStorage.removeItem('set_CategoriesForNav_timezone')
      }
    }
    if (process.client) {
      if (localStorage.getItem('set_CategoriesForNav')) {
        const categories = await JSON.parse(
          encryptor.decrypt(localStorage.getItem('set_CategoriesForNav'))
        )
        commit('set_data', categories)
        commit('set_user_category', categories)
        return
      }
    }

    const reqData = {}
    if (this.$auth.loggedIn) {
      reqData._accessToken = this.$auth.getToken('customLocal')
    }
    try {
      commit('set_loading', true)
      // commit('reset_data')
      const { data } = await this.$axios({
        method: 'post',
        baseURL: process.env.SPORTSBOOK_API_URL,
        data: reqData,
        url: 'category/getCategoriesForNavigation'
      })

      let categories = data.data
      if (!showHrAndGhr)
        categories = categories.filter(
          (c) => !['Horse Racing', 'Greyhound Racing'].includes(c.name)
        )

      if (this.$auth.loggedIn && this.$auth.user) {
        if (!this.$auth.user.horseRacing) {
          categories = categories.filter(
            (category) => category.name !== 'Horse Racing'
          )
        }
        if (!this.$auth.user.greyhoundRacing) {
          categories = categories.filter(
            (category) => category.name !== 'Greyhound Racing'
          )
        }
        // if (!this.$auth.user.cricket) {
        //   categories = categories.filter(
        //     (category) => category.name !== 'Cricket'
        //   )
        // }
        // if (!this.$auth.user.soccer) {
        //   categories = categories.filter(
        //     (category) => category.name !== 'Soccer'
        //   )
        // }
        // if (!this.$auth.user.tennis) {
        //   categories = categories.filter(
        //     (category) => category.name !== 'Tennis'
        //   )
        // }
        if (!this.$auth.user.sportsbook) {
          categories = categories.filter(
            (category) =>
              category.name !== 'Cricket' &&
              category.name !== 'Soccer' &&
              category.name !== 'Tennis' &&
              category.name !== 'Horse Racing' &&
              category.name !== 'Greyhound Racing'
          )
        }
      }

      commit('set_data', categories)
      localStorage.setItem(
        'set_CategoriesForNav',
        encryptor.encrypt(JSON.stringify(categories))
      )
      localStorage.setItem(
        'set_CategoriesForNav_timezone',
        new Date().getTime()
      )
      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  },
  async getPremiumSportsbook({ commit }) {
    // This will stored data for 5 mins.
    if (
      localStorage.getItem('set_AllMenu_timezone') &&
      localStorage.getItem('set_AllMenu')
    ) {
      const aa = localStorage.getItem('set_AllMenu_timezone')
      const now = new Date().getTime()
      const distance = now - aa
      const seconds = Math.floor(distance / 1000)
      const minutes = Math.floor(seconds / 60)
      if (minutes >= 5) {
        localStorage.removeItem('set_AllMenu')
        localStorage.removeItem('set_AllMenu_timezone')
      }
    }
    if (process.client) {
      if (localStorage.getItem('set_AllMenu')) {
        const data = await JSON.parse(
          encryptor.decrypt(localStorage.getItem('set_AllMenu'))
        )
        commit('set_data_premium_sportsbook', data)
        return
      }
    }

    const reqData = {}
    if (this.$auth.loggedIn) {
      reqData._accessToken = this.$auth.getToken('customLocal')
    }
    try {
      commit('set_loading', true)
      // commit('reset_data')
      if (this.$auth?.$state?.user?.premiumsportsbook === false) {
        commit('set_data_premium_sportsbook', [])
        localStorage.setItem(
          'set_AllMenu',
          encryptor.encrypt(JSON.stringify([]))
        )
      } else {
        const { data } = await this.$axios({
          method: 'post',
          baseURL: process.env.CONFIG_V2_API_URL,
          data: reqData,
          url: 'betbymenu/getAllMenu'
        })
        commit('set_data_premium_sportsbook', data.data)
        localStorage.setItem(
          'set_AllMenu',
          encryptor.encrypt(JSON.stringify(data.data))
        )
      }

      localStorage.setItem('set_AllMenu_timezone', new Date().getTime())
      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  },
  // api call for get competition of left side menu
  async getCompetition({ commit }, payload) {
    // This will stored data for 10 mins.
    if (
      localStorage.getItem('set_CompetitionData_timezone_' + payload) &&
      localStorage.getItem('set_CompetitionData_' + payload)
    ) {
      const aa = localStorage.getItem('set_CompetitionData_timezone_' + payload)
      const now = new Date().getTime()
      const distance = now - aa
      const seconds = Math.floor(distance / 1000)
      const minutes = Math.floor(seconds / 60)
      if (minutes >= 10) {
        localStorage.removeItem('set_CompetitionData_' + payload)
        localStorage.removeItem('set_CompetitionData_timezone_' + payload)
      }
    }
    if (
      process.client &&
      localStorage.getItem('set_CompetitionData_' + payload)
    ) {
      const data = await JSON.parse(
        encryptor.decrypt(
          localStorage.getItem('set_CompetitionData_' + payload)
        )
      )
      commit('set_competition_data', data)
      return
    }

    try {
      commit('set_loading', true)
      // commit('reset_data')
      const response = await this.$axios({
        method: 'post',
        baseURL: process.env.SPORTSBOOK_API_URL,
        data: {
          _accessToken: this.$auth.getToken('customLocal'),
          name: payload,
          eventTypeId: '1'
        },
        url: 'category/getCompetitions'
      })

      if (response && response.data && response.data.value) {
        if (
          this.$router.currentRoute.params &&
          this.$router.currentRoute.params.category &&
          this.$router.currentRoute.params.category !== 'horse-racing' &&
          this.$router.currentRoute.params.category !== 'greyhound-racing'
        ) {
          commit('set_competition_data', response.data.data)
          localStorage.setItem(
            'set_CompetitionData_' + payload,
            encryptor.encrypt(JSON.stringify(response.data.data))
          )
        }
      }

      localStorage.setItem(
        'set_CompetitionData_timezone_' + payload,
        new Date().getTime()
      )
      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  },

  // api call for get event of cpmpition left side menu
  async getEventCompetition({ commit }, payload) {
    try {
      // commit('reset_data')
      const response = await this.$axios({
        method: 'post',
        baseURL: process.env.SPORTSBOOK_API_URL,
        data: {
          _accessToken: this.$auth.getToken('customLocal'),
          _id: payload
        },
        url: 'category/getEventsOfCompitition '
      })

      if (response && response.data && response.data.value) {
        if (
          this.$router.currentRoute.params &&
          this.$router.currentRoute.params.category &&
          this.$router.currentRoute.params.category !== 'horse-racing' &&
          this.$router.currentRoute.params.category !== 'greyhound-racing'
        ) {
          commit('set_competition_data', response.data.data)
        }
      }
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  }
}
